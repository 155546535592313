/* Add here all your css styles (customizations) */
.main-background {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

#root > .container {
    max-width: 100% !important;
    width: 100%;
    padding: 0;
    margin: 0;
}




.u-header__logo-img {
    height: 60px;
}

.opacity-80{
    opacity: 0.8;
}

@media only screen and (min-width: 1200px) {
    #js-header .g-py-25 {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .u-header__logo-img {
        height: 48px;
    }
}


.background-1 {
    background-image: url(../assets/img/1600x1920.jpg);
}

.history {
    padding-bottom: 6.42857rem;
}

.history .copy-container {
    padding: 2.14286rem;
}


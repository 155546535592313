#processes .background-image1 {
    background-image: url(../assets/img-temp/80x80/img1.jpg);
}

#processes .background-image2 {
    background-image: url(../assets/img-temp/80x80/img2.jpg);
}

#processes .background-image3 {
    background-image: url(../assets/img-temp/80x80/img3.jpg);
}

#processes .background-image4 {
    background-image: url(../assets/img-temp/80x80/img4.jpg);
}

#processes .container {
    width: auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
#home{
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    z-index: 0;
}

#myVideo {
    display: block;
}


#mobile-background {
    display: none;
    background-image: url('../assets/img/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.video-overlay {
    background-image: url(../assets/img/bg/top-bg.png);
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    opacity: 0.8;
}

@media (orientation: portrait) {
    #myVideo {
        display: none;
    }

    #mobile-background {
        display: block;
    }
}